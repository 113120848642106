* { margin: 0; padding: 0; font-family: 'Josefin Sans', sans-serif;font-family: 'Poppins', sans-serif; }


/* 2 button on top */

.eych2{
  color: #C81817;
  padding: 12% 10px 30px 10px;
  margin-top: 7%;
}
.eych22{
  color: #C81817;
  padding: 1% 10px 10px 10px;
  font-weight: 700;
}

.frdiv1{
  padding: 0 20px 0 20px;
  color: #787878;
  line-height: 23px;
}

.cardss{
  justify-content: space-around;
  display: flex;
}

.click1{
  text-decoration: underline;
  color: #C81817;
  font-size: 18px;
}

.showmine{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-label {
  font-size: 13px;
}

.btn1{
  border-radius: 4px;
  background-color: #C81817;
  color: white;    
  border: none;
  padding: 4px;
}

ol {
  list-style-type: disc; 
  color: #C81817; 
}
ul {
  list-style-type: disc; 
  color: #C81817; 
}


.bold{
  color: #C81817; 
}