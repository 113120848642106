
* { margin: 0; padding: 0; font-family: 'Josefin Sans', sans-serif;font-family: 'Poppins', sans-serif; }


/* navvv */
.mainnav{
  border: red solid;
}

.subnav{
  display: flex;
}

.submainnav{
  border: 1px green solid;
}

.submainnav1{
  border: 1px green solid;
}

/* home */

.frtitle{
  padding: 20px 10px 0 10px;
  color: #C81817;
}

.frdiv{
  padding: 0 20px 0 20px;
  color: #787878;
  line-height: 25px;
  font-size: 18px;
}
.frdiv1{
  padding: 0 28px 25px 28px;
  color: #787878;
  line-height: 23px;
  font-size: 18px;
}

.eych3{
  color: #C81817;
  padding: 12px 10px 30px 10px;
}

.eych2{
  color: #C81817;
  padding: 12px 10px 30px 10px;
}

.majorpic {
  padding: 15px;
}

.cards{
  display: flex;
  justify-content: center;
}

.cardtitle{
  color: #C81817;
  font-size: 22px;
}
.cardtitle1{
  color: #C81817;
  font-size: 21px;
}

.cardtext{
  color: #787878;
  font-size: 17px;
}

.cardtext1{
  color: #787878;
  font-size: 18px;
}

.card{
  background-color: #F7F7F7;
  box-shadow: 2px 2px 14px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 8%;
}

.allcontacts{
  padding: 0 23px 20px 23px;
}

.click{
  text-decoration: underline;
  color: #C81817;
  font-size: 14px;
}

.naymlink{
  color: #C81817;
}