* { margin: 0; padding: 0; font-family: 'Josefin Sans', sans-serif;
    font-family: 'Poppins', sans-serif; }


.parafoot{
    font-size: 11px;
    color: white;
}

.bgfoot{
    background-color: #C81817;
    padding: 10% 5% 10% 5%;
    margin-top: 10%;
}